import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../../css/common.css";

import Seo from "../../components/SeoComponent";
import Header from "../../components/Header";
import TitlePage from "../../components/ui/TitlePage";
import SectionTextPage from "../../components/ui/SectionTextPage";
import SectionImage from "../../components/ui/SectionImage";
import SectionImagePlayer from "../../components/ui/SectionImagePlayer";
import SectionSubMenu from "../../components/ui/SectionSubMenu";
import Footer from "../../components/Footer";

import { EXPERTISES_SUBMENU } from "../../constants/constants";

const Construction = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t("page-expertise.construction.metadata.name")}
        description={t("page-expertise.construction.metadata.description")}
      />
      <Header />
      <TitlePage category={t("page-expertise.construction.category")} title={t("page-expertise.construction.title")} />
      <SectionImage
        imageHD="construction.jpg"
        imageMD="construction-md.jpg"
        imageSD="construction-sd.jpg"
        alt="construction"
      />

      <SectionTextPage>
        <p className="section-title">{t("page-expertise.construction.s1-1")}</p>
        <p>{t("page-expertise.construction.s1-p1")}</p>
        <p>{t("page-expertise.construction.s1-p2")}</p>
        <p>{t("page-expertise.construction.s1-p3")}</p>
      </SectionTextPage>

      <SectionTextPage mode="dark">
        <p className="section-title">{t("page-expertise.construction.s2-1")}</p>
        <p>{t("page-expertise.construction.s2-p1")}</p>
        <p>{t("page-expertise.construction.s2-p2")}</p>
      </SectionTextPage>

      <SectionImagePlayer
        imageHD="construction-2.jpg"
        imageMD="construction-2-md.jpg"
        imageSD="construction-2-sd.jpg"
        title="&nbsp;"
      />

      <SectionSubMenu menus={EXPERTISES_SUBMENU} title={t("page-expertise.other-menu-title")} codeExclude="btp" />

      <Footer />
    </>
  );
};

export default Construction;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
